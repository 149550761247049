// color

$color-text-basic: #ffffff;
$text_color: #dedede;
$activ_text_color: #858585;
$menu_bagraund_color: rgba(20,17,14,.7);
$button_bagraund_color:#101010;
$border-section-color: #77777785;

// ## Fonts
$Calistoga: 'Calistoga';
$Big_Caslon: 'Big Caslon';
$Junction: 'Junction';

// ## font-size

$text_hight: 2em;
$big_text: 2.5em;



// ## wi

$mobil_width: 767px;

$img_width: 10%;

