.header{
  background-image: url(../img/heafer_backgraund.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  position: relative;
  &-menu{
    width: 99%;
    background-color: $menu_bagraund_color;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.logo {
  width: 70px;
  height: auto;
}
.logo__link_mob {
  display: none;
}

@media only screen and (max-width: $mobil_width){
  .logo__link_mob {
      display: block;
      width: 70px;
      padding: 5px;
      box-sizing: border-box;
  }
}

.main__menu {
  position: relative;
}

.humburger__logo {
    width: 40px;
    height: 40px;
    margin: 10px;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    display: none;
    z-index: 3;
    position: absolute;
    right: 10px;
    top: 15px;
}

@media only screen and (max-width: $mobil_width){
  .humburger__logo {
      display: block;
  }
}

.humburger__logo span:nth-child(1) {
    top: 0;
    transform-origin: left center;
}

.humburger__logo span:nth-child(2) {
    top: 10px;
    transform-origin: left center;
}

.humburger__logo span:nth-child(3) {
    top: 20px;
    transform-origin: left center;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
}

.humburger__logo span {
    display: block;
    position: absolute;
    height: 5px;
    width: 40px;
    background-color: #937966;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    cursor: pointer;
}

.menu {
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;

  a{
    text-decoration: none;
    color: $text_color;
    &:hover{
      color: $activ_text_color;
    }
  }
  &__link {
      text-transform: uppercase;
      display: block;
    }

  &__item {

  }
}

@media only screen and (max-width: $mobil_width){
  .menu {
      position: absolute;
      top: -100%;
      opacity: 0;
      transition: top .5s;
  }
}

@media only screen and (max-width: 767px){
  .menu_active {
      opacity: 1;
      display: flex;
      position: fixed;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100vh;
      background-color: #10100e;
      z-index: 1;
      top: 0;
      left: 0;
  }
}

.text-header{
  position: absolute;
  padding: 20px;
  bottom: 10%;
  color: $text_color;

}
@media only screen and (max-width: $mobil_width){
  .text-header{
    //top:15%;
    font-size: 0.6em;
    line-height: 1.4;
  }
  
}

.record-onlain {
  position: fixed;
  z-index: 1000;
  color: $text_color;
  bottom: 30px;
  background-color: $button_bagraund_color;

  &-active{
    background:#363837d1;
    position: fixed;
    opacity: 0;
    bottom: 2%;
    left: 50%;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    transform: translateX(-75px);

  }

}

@media only screen and (max-width: $mobil_width){
  .record-onlain {
    background: #363837d1;
    position: fixed;
    bottom: 2%;
    left: 50%;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    transform: translateX(-75px);
  }

}

