.about{
    padding-top: 3%;
    img{
        background-color: blanchedalmond;
        border-radius: 50%;
        padding: 5%;
    } 

    div{
        padding: 3% 8%;
    }
}

@media only screen and (max-width: $mobil_width){
    .about{
        border-bottom: 1px solid silver;
    }

}