.gallery{
    background-color: black;
    border-bottom: 5px solid $border-section-color;
    // width: 100%;
    color: $text_color;
    padding-bottom: 10%;

    .single-item{
        width: 80%;
        padding: 0 10%;
        margin: 0 auto;

        img{
            margin: 10px;
        }

        .gallery-img{
            max-height: 100%;
        }

        @media only screen and (max-width: 767px){
            .gallery-img {
                max-height: 250px;
            }
        }

        .slick-prev{
            left: 0;
        }

        .slick-next{
            right: 0;
        }
    }
}    

    // img{
    //     display: block;

    // }
// }
// .carousel-inner img{
//     height: 100 px;
// }  