.masters{
    background: url("../img/bg_team.jpg") no-repeat;
    background-color: #0e0d0e;
    background-size: contain;
    padding-top: 20em;
    
    p{
        padding: 3%;
        
    }

    div{
        margin-bottom: 10px;
    }

}