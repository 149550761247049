footer{
    position: relative;
    background: url("../img/footer_bg.jpg") no-repeat ;
    background-color: #252525;
    height: 717px;


    .social{
        margin-left: 3%;
        background-color: silver;
        border: 1px solid sienna;
        padding: 5px;
        width: 48px;

        a{
            margin: 5px 0;
        }


    }

    .slogan{
        color: $color-text-basic; 
        font-family: $Calistoga, cursive;
        font-size: 2.6rem;
        padding-left: 5%;
        letter-spacing: 0.2rem;
        word-spacing: 6px
    }
    .stud{
        position: absolute;
        border-top:2px solid sienna;
        text-align: right;
        padding: 5% 5%;
        width: 100%;
        height: 10%;
        bottom: 0;
        left: 0;
        color: $text_color;

    }
    .mob_tel{
        display: none;
    }
@media only screen and (max-width: $mobil_width){
    .mob_tel {
        display: block;
        position: fixed;
        background-color: silver;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        bottom: 30px;
        right: 30px;
        padding: 5px;
        z-index: 120;
    }
     
}

    

}