@import "import/reset";
@import url('https://fonts.googleapis.com/css?family=Calistoga&display=swap');
@import "import/setings";
@import "../../fonts/caslon/stylesheet.css";
@import "../../fonts/junction/junction.css";

@import "import/general";
@import "import/header";
@import "import/services";
@import "import/gallery";
@import "import/about";
@import "import/masters";
@import "import/contacts";
@import "import/footer";
