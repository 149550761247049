.selector-for-some-widget {
  box-sizing: content-box;
}
.text {
  text-align: left;
  font-size: $text_hight;
  font-family: $Junction;
  color: $color-text-basic;

  &-big{
    font-size: $big_text;
  }

  &-description{
    padding-top:5%;
    font-style: italic;
    text-align: center;
    line-height: 1.5;
    font-weight: 700;
    font-size: 20px;
    font-family: $Junction;
    color: $color-text-basic;
  }
}

.section{
  background-color: black;
  border-bottom: 5px solid $border-section-color;
  width: 100%;
  color: $text_color;
  padding-bottom: 10%;
}

h2{
  text-align: center;
  padding: 5% 0;
  font-size: 40px;
  border-top: 2px solid silver;
  text-shadow: 3px -5px 7px #945220;
  
}

h3{
  text-align: center;
  padding: 5% 0;
  font-size: 20px;
  border-bottom: 1px solid silver;
  text-shadow: 3px -5px 7px #ffebcd;
}

.center{
  margin: 0 32% 5%;
}

@media only screen and (max-width: $mobil-width){
  .center{
    margin: 0 38% 5%;
  }
}

//online record button
.ms_booking{
  color: $text_color;

  &:hover{
    text-decoration: none;
    color: $activ_text_color;
  }
}
