.maps{
    width: 100%;
    height: 300px;
}
.contact{
    margin: 3% 0 3% 0;
    padding: 0 2% ;
    border: 2px solid sienna;

    li{
        position: relative;
        padding: 2% 0;
        padding-left: 42px;
        line-height: 2;
        border-bottom: 1px solid sienna;

        &:last-child{
            border-bottom: 0px solid sienna;
        }

        img{
            position: absolute;
            background-color: sienna;
            width: 32px;
            height: 32px;
            top: 50%;
            transform: translateY(-16px);
            left: 0;
            border-radius: 20%;;
        }

        span{
            display: block;
            color: $activ_text_color;
        }
    }

}