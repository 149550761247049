.service{
    // background: url("../img/service_bg.JPG");
    background-color: black;


    .name-service{
        position: relative;
        text-align: left;
        font-size: 1.3em;
        padding: 0 20% 0 0%;
        line-height: 3.5;;

        span{
            position: absolute;
            right: 0;
            color: $activ_text_color; 
        }
    }

    @media only screen and (max-width: $mobil_width) {
        .name-service{
            // display: inline-block;
            padding: 2% 0;
            text-align: center;
            border-bottom: 2px solid $border-section-color;

            span{
                position: relative;
                display: inline-block;
                width: 100%;
            }
        }
        
    }


}